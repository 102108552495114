require('./bootstrap');

import Vue from 'vue';
window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

Vue.component('bs4-typeahead', require('./components/bs4-controls/Typeahead.vue').default);
Vue.component('bs4-tag-input', require('./components/bs4-controls/TagInput.vue').default);
Vue.component('bs4-datepicker', require('./components/bs4-controls/Datepicker.vue').default);
Vue.component('bs4-search-field', require('./components/bs4-controls/SearchField.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.


*/
const app = new Vue({
    el: '#app',
});

$(function() {
    $('input.file-input-custom').change(function (e){
        $(this).parents('form').submit();
    });	
});
