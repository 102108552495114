var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"on-click-outside",rawName:"v-on-click-outside",value:(_vm.close),expression:"close"}],staticClass:"search-field"},[_c('div',{staticClass:"form-control",attrs:{"disabled":_vm.disabled},on:{"click":_vm.toggle}},[_c('div',{staticClass:"search-result"},[_vm._v("\n      "+_vm._s(_vm.selectedItem[_vm.valueProperty])+"\n    ")])]),_vm._v(" "),(_vm.isOpen)?_c('div',{staticClass:"search-panel"},[_c('autocomplete',{attrs:{"initial-items":_vm.initialItems,"src":_vm.src,"limit":10,"clear-on-select":true,"value-property":_vm.valueProperty,"show-all-available":true,"autohide":false,"container-class":"search-container"},on:{"autocompleted":_vm.handleAutocomplete,"error":function($event){return _vm.$emit('error', _vm.response)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var autocompleteBindings = ref.autocompleteBindings;
var autocompleteHandlers = ref.autocompleteHandlers;
return [_c('input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"type":"text","name":_vm.name,"id":_vm.id,"autocomplete":"off"}},'input',autocompleteBindings,false),autocompleteHandlers)),_vm._v(" "),_vm._t("search-icon",function(){return [_c('span',{staticClass:"fa fa-search search-icon"})]})]}},{key:"items",fn:function(ref){
var autocompleteItems = ref.autocompleteItems;
var searching = ref.searching;
var markItem = ref.markItem;
var selectItem = ref.selectItem;
return [_c('div',{staticClass:"autocomplete-items"},[(!autocompleteItems.length && searching)?_c('div',[_vm._v("\n            "+_vm._s(_vm.searchingLabel)+"\n          ")]):_c('ul',{staticClass:"list-unstyled"},_vm._l((autocompleteItems),function(item,index){return _c('li',[_c('a',{attrs:{"href":""},on:{"mousedown":function($event){$event.preventDefault();return selectItem.apply(null, arguments)},"mousemove":function($event){return markItem(index)}}},[_vm._v("\n                "+_vm._s(item[_vm.valueProperty])+"\n              ")])])}),0)])]}}],null,true)})],1):_vm._e(),_vm._v(" "),_vm._t("caret",function(){return [_c('span',{staticClass:"caret"})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }